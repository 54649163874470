enum AppStore {
  android,
  ios,
}

interface AppStoreProps {
  type: AppStore
  src: string
}

class AndroidStore implements AppStoreProps {
  constructor(
    public type: AppStore = AppStore.android,
    public src: string = "/button_googleplay.png"
  ) {}
}

class AppleStore implements AppStoreProps {
  constructor(
    public type: AppStore = AppStore.ios,
    public src: string = "/button_appstore.png"
  ) {}
}

// const COMING_SOON_TXT_COLOR: string = "text-yellow-400"

export function getImgPath(src: string) {
  return `/imgs/${src}.jpg`
}

export function getIconPath(src: string) {
  return `/imgs/${src}_icon.png`
}

export function combineClassWithTxtCol(
  className: string,
  textCol: string | undefined
) {
  return `${className} ${textCol ? `${textCol}` : ""}`
}

export interface LogoProps {
  store: AppStoreProps
  link: string
}

export const aboutBannerImg: string = "/imgs/lonesurvivor.jpg"

export const contactUsBannerImg: string = "/imgs/sycamorehill.jpg"

export const browseAllAppsIcon: string = "/imgs/sycamorehill_icon.png"

export const bannerHeight: string = "35vw"

export type AppInfoProps = {
  title: string
  subtitle: string
  subtitleColor?: string | undefined // default to text-gray-400 if undefined. refer to : https://tailwindcss.com/docs/text-color/#app
  description: string
  src: string // img src
  srcDetails?: string
  stores: Array<LogoProps>
  tag: string // link to /app/${tag}
  bgDescription: string // the color of the bg of the description particularly used in the AppInfoGrid and AppDetails components
  isHeadline: boolean // when it's true, it will be shown in the index headline as well as /apps headline
  isBottomBanner: boolean
  trailer?: string | undefined // youtube trailer link, set as undefined if there is no trailer for the app
  comingSoon?: boolean
}

export const appInfoModels: AppInfoProps[] = [
  {
    title: "The Mystery of Haunted Hollow",
    subtitle: "Chapter 1 in the Mystery of Haunted Hollow Series!",
    description:
      "Embark on an Adventure in this Haunted Town. Unveil secrets for a shocking twist ending that will blow your mind!",
    src: "hh1",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.pointclickgames.mysteryofhauntedhollow",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/the-mystery-of-haunted-hollow/id967661297",
      },
    ],
    tag: "hh1",
    bgDescription: "bg-gray-900",
    isHeadline: true,
    isBottomBanner: false,
  },
  {
    title: "The Mystery of Haunted Hollow 2",
    subtitle: "Chapter 2 in the Mystery of Haunted Hollow Series!",
    description:
      "You’ve arrived in Nightfall Village! Solve intriguing puzzles to unveil the final ending in the Mystery of Haunted Hollow Saga!",
    src: "hh2",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.pointclickgames.mysteryofhauntedhollow2&hl=en_US",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/mystery-of-haunted-hollow-2-point-click-games/id1147843997",
      },
    ],
    tag: "hh2",
    bgDescription: "bg-gray-900",
    isHeadline: true,
    isBottomBanner: false,
  },
  {
    title: "Fatal Affair On Cape Fog",
    subtitle:
      "Your Summer visit to the idyllic town of Cape Fog turns out to be a twisted story out of a murder mystery novel",
    description:
      "You and your husband venture off to the idyllic town of Cape Fog to relax and unwind. As you delve into this charming town, you discover distressing information about your husband's potential involvement in the death of a young woman. Will your discoveries be more sinister and twisted than you could have ever fathomed?",
    src: "capefog",
    srcDetails: "summerhouse",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.midnightadventures.fatalaffaircapefogFREE&hl=en_US&gl=US",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/fatal-affair-on-cape-fog/id1574123534",
      },
    ],
    tag: "fatal-affair-on-cape-fog",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: true,
    comingSoon: true,
  },
  {
    title: "Deadly Secrets on Autumn Drive",
    subtitle:
      "Unveil Deadly Secrets on Autumn drive buried years ago as you find disturbing clues you wish you could unsee in this thrilling Adventure game!",
    description:
      "You and your only daughter have moved into a new neighborhood to fix up a home that is in desperate need of repair. A friendly old woman introduces herself, but is seemingly obsessed with you, and the home you have moved into. You begin to unveil secrets about this neighborhood you wish you could unsee in this thrilling Adventure Game!",
    src: "autumndrive",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.midnightadventures.deadlysecretsautumndriveFREE&hl=en&gl=US",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/deadly-secrets-on-autumn-drive/id1538002906",
      },
    ],
    tag: "deadly-secrets-on-autumn-drive",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: true,
  },
  {
    title: "The Secret of Hollywood Motel",
    subtitle:
      "As the late actress Nettlebaum's Grandson, investigate a crime buried decades ago at the Eerie and abandoned Hollywood Motel.",
    description:
      "As the late actress Nettlebaum's Grandson, your investigation starts at the footsteps of the infamous Hollywood Motel, where a movie set went awry. Do you have what it takes to solve the Secret of Hollywood Motel?",
    src: "secretmotel",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.midnightadventures.secretofhollywoodmotelFREE",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/the-secret-of-hollywood-motel/id1525775435",
      },
    ],
    tag: "secret-of-hollywood-motel",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: true,
  },
  {
    title: "The Secret on Sycamore Hill",
    subtitle:
      "Explore buried secrets hidden in the Manor in Secret of Sycamore Hill!",
    description:
      "Explore a dark and foreboding mansion by solving puzzles, and piecing together clues to solve the big mystery of your Aunt’s whereabouts!",
    src: "sycamorehill",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.midnightadventures.secretonsycamorehillPAID&hl=en_US",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/the-secret-on-sycamore-hill/id1453590849",
      },
    ],
    tag: "the-secret-on-sycamore-hill",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: false,
  },
  {
    title: "The Long Journey",
    subtitle: "Embark on the Adventure of a Lifetime in the Long Journey!",
    description:
      "You have crash landed in what appears to be another world. Explore every nook and cranny of this beautiful place, and find out your true destiny!",
    src: "longjourney",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.midnightadventures.thelongjourney&hl=en_US",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/the-long-journey-adventure/id1322497988",
      },
    ],
    tag: "long-journey",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: false,
  },
  {
    title: "Lost & Alone",
    subtitle: "Explore a vast land in this stunning Adventure Game!",
    description:
      "Stunning scenery awaits you in this gorgeous point and click adventure game! Solve puzzles, and trek through marvelous scenery that will take your breath away!",
    src: "lostalone",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.pointclickgames.lostadventure&hl=en_US",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/lost-alone-adventure-games/id1262591716",
      },
    ],
    tag: "lost-and-alone",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: false,
  },
  {
    title: "Block Puzzle Gardens",
    subtitle: "Play a Gorgeous Block Puzzle Gardens Game!",
    description:
      "Play several fun, classic block games including triangles, blocks and more in this gorgeous garden themed Block Puzzle Game!",
    src: "block_puzzle_gardens",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.midnightadventures.blockpuzzlegardensFREE&hl=en_US",
      },
      {
        store: new AppleStore(),
        link: "https://apps.apple.com/us/app/block-puzzle-gardens/id1483999036",
      },
    ],
    tag: "block-puzzle-gardens",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: false,
  },
  {
    title: "The Lone Survivor",
    subtitle: "Explore a vast land after crash landing on a deserted island!",
    description:
      "Explore unfamiliar places on an abandoned aircraft as you narrowly escape death, solve puzzles, and unveil the big mystery with a twist ending!",
    src: "lonesurvivor",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.midnightadventures.lonesurvivor&hl=en_US",
      },
      {
        store: new AppleStore(),
        link: "https://apps.apple.com/us/app/the-lone-survivor/id1403160659",
      },
    ],
    tag: "lone-survivor",
    bgDescription: "bg-gray-900",
    isHeadline: false,
    isBottomBanner: false,
  },
]

// TOP BANNER for both / and /apps
export const topBanners: AppInfoProps[] = appInfoModels.filter(
  x => x.isHeadline
)

// BOTTOM BANNERS in /
export const bottomBanners: AppInfoProps[] = appInfoModels.filter(
  x => x.isBottomBanner
)

// GRID APPS IN APPS PAGE
export const gridApps: AppInfoProps[] = appInfoModels.filter(
  x => !topBanners.includes(x)
)

export const comingSoonApps: AppInfoProps[] = appInfoModels.filter(
  x => x.comingSoon
)
