import React from 'react'

const Layout: React.FunctionComponent = (props) => {
  return (
    <div
      style={{ minHeight: '82vh' }}
      className="w-full flex flex-col items-center bg-gray-900 text-gray-400">{props.children}</div>
  );
}

export default Layout;