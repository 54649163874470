import React, { ReactElement } from "react"
import Header from '../components/header'
import Footer from "../components/footer"
import { contactUsBannerImg, bannerHeight } from "../models/models"
import Layout from "../components/layout"

function ContactUs(): ReactElement {
    return (
        <>
            <Header page={'Contact Us'} />
            <Layout>
                <div className="relative w-full overflow-hidden" style={{ height: bannerHeight }}>
                    <img className="inset-0 absolute w-full" alt="banner_img" src={contactUsBannerImg} />
                </div>
                <div className="py-2 px-2 md:py-10 md:px-64 text-center mb-10">
                    <p className="text-md md:text-xl">Have a question or just want to say hello? Drop us a line! We'd love to hear from you!</p>
                    <div className="mt-10">
                        <a className="text-blue-500 font-bold text-lg" href="mailto: pointclickgames@gmail.com">pointclickgames@gmail.com</a>
                    </div>
                </div>
            </Layout>
            <Footer />
        </>
    )
}

export default ContactUs
